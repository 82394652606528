<template>
  <div id="PrivilegioUsuarioEmpreendimento">

    <!-- CONTAINER -------------------------------------------------------->
    <v-container class="container mt-n6 mx-0 pa-0 ">

    <v-card class="rounded-lg tabela align-center w-100" tabindex="-1" elevation="0">

      <v-text-field
        id="txtBuscar"
        ref="txtBuscar"
        prepend-inner-icon="mdi-magnify"
        dense
        v-model="search"
        flat
        filled
        label="Buscar..."
        clearable
        tabindex="-1"
        style="width:300px"
        class="mt-3 ml-3 mb-n5"
        solo
        background-color="#F4F4F4"
      >
      </v-text-field>

      <!-- TABELA PRIVILEGIOS -------------------------------------------------------->
      <v-data-table
        class="rounded-lg"
        :items="empreend_evento"
        :items-per-page="10000"
        :loading="loading"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th class="ml-8 mt-4" width="20px">
                <v-menu
                  content-class="my-menu" 
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioEmpreendimento_alterar')"
                      color="grey"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <!-- Adicionar de Todas as Aplciações -->
                  <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                    <v-list-item
                      @click="adicionarTodos()">
                      <v-icon color="green" class="mr-2 icon-menu">
                        mdi-pencil
                      </v-icon>
                      Adicionar eventos de todos os empreendimentos
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="removerTodos()">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                      Remover eventos de todos os empreendimentos
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="dialogoUsuarios = true">
                      <v-icon color="blue" class="mr-2 icon-menu">mdi-account-switch</v-icon>
                      Copiar os privilégios de outro usuário
                    </v-list-item>
                  </v-card>
                  <!-- FIM Adicionar de Todas as Aplciações -->
                </v-menu>
              </th>
              <th v-for="h in headers" :key="h.value" :class="h.class" class="py-2">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr >
            <td class="ml-8 mt-4" width="20px">
              <v-menu content-class="my-menu" 
                      transition="slide-y-transition"
                      bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioEmpreendimento_alterar')"
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <!-- Menu Editar / Excluir / etc -->
                <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                  <v-list-item
                    @click="adicionarTodosEmpreendimento(item.cod_empreendimento)">
                    <v-icon color="green" class="mr-2 icon-menu">
                      mdi-pencil
                    </v-icon>
                    Adicionar eventos deste empreendimento
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="removerTodosEmpreendimento(item.cod_empreendimento)">
                    <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                    Remover eventos deste empreendimento
                  </v-list-item>
                </v-card>
                <!-- FIM Menu Editar / Excluir / etc -->

              </v-menu>
            </td>
           
            <td  style="width:450px!important">
              <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">{{ item.descricao }}</span>
            </td>

            <td class="">

              <v-col class="py-2 px-0">
                <v-select
                  v-model="attrArr"
                  :items="item.items"
                  item-text="evento_descricao"
                  item-value="cod_empreend_evento"
                  chips
                  :label="item.evento_descricao"
                  hide-details="auto"
                  background-color="transparent"
                  multiple
                  solo
                  dense
                  flat
                  deletable-chips
                  hide-selectedddd
                  @change="changed()"
                  :readonly="!store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioEmpreendimento_alterar')"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      :color="item.evento_cor"
                      close
                      @click="select"
                      @click:close="remove(item.cod_empreend_evento)"
                      class="chips-font mb-1"
                    >
                      <strong>{{ item.evento_descricao }}</strong>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </td>

          </tr> 
        </template>
      </v-data-table>

      <!-- FIM TABELA MENSAGENS -------------------------------------------------------->   

    </v-card>

    </v-container>
    <!-- FIM CONTAINER -------------------------------------------------------->

    <div style="height:20px"></div>

    <v-footer
      id="PrivilegioUsuarioEmpreendimento_alterar"
      color="transparent"
      elevation="0"
      class="footer justify-center pt-3 mt-12">
      <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        @click="cancelar()"
      >
        Cancelar
      </v-btn>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>   

    <!-- DIALOGO Usuarios -------------------------------------------------------->
    <v-container v-if="dialogoUsuarios">
      <v-dialog
        v-model="dialogoUsuarios"
        scrollable
        persistent
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho do Proprietario -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Usuários
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogoUsuarios = false; store_Pessoa.pessoa_selecionado = null" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Proprietario -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Proprietario -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialogoUsuarios = false; store_Pessoa.pessoa_selecionado = null"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="dialogoUsuarios = false; copiarDoUsuario(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
import { API } from "../../../services/API"
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../../services/constantes";
import { sortBy, buscaSemAcentosJSON } from "../../../services/funcoes"
import store_site from "../../../store/store_site"
import store_usuario from "../../../store/store_usuario";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue";

export default {
  name: "PrivilegioUsuarioEmpreendimento",

  props: {
          _cod_pessoa: {
            type: Number,
          }
  },

  components: {
    PessoaConsultaMobile
  },

  data() {
    return {
      store_site        : store_site,
      store_usuario     : store_usuario,
      store_Pessoa      : store_Pessoa,

      COR_SUBTITULO     : COR_SUBTITULO,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      
      search            : null,
      valid             : true,
      loading           : false,
      dialogoUsuarios   : false,

      /* TITULOS TABELA PRIVILEGIOS */
      headers: [
        { text: "Empreendimentos", value: "Descrição", class: 'title-text' },
        { text: "Privilégios", value: "Privilegios", class: 'title-text' },
      ],

      attrArr               : [],
      attrArrAux            : [],
      empreend_evento       : [],
      empreend_evento_Aux   : [],

    };
  },

  async mounted() {
    if (this._cod_pessoa)
      this.seleciona_usuario(this._cod_pessoa)
    console.log('MOUNTED:::', this.store_usuario)
    this.empreend_evento     = this.store_usuario.empreend_evento;
    this.empreend_evento_Aux = this.store_usuario.empreend_evento;
    this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
  },

  watch: {
    search(val) {
      if (!val)
        this.empreend_evento = this.empreend_evento_Aux;
      else
        this.empreend_evento = buscaSemAcentosJSON(this.empreend_evento_Aux, 'descricao', val);
    },

    async _cod_pessoa(val) {
      if (val)
        this.seleciona_usuario(val)
    }
  },

  methods: {

    async seleciona_usuario(val) {
      this.loading = true;
      // console.log('COD_PESSOA::', val)
      this.attrArr = await this.busca_pessoa_empreend_evento_privilegios( val );
      if (!this.attrArr)
        this.attrArr = [];
      this.attrArrAux = [...this.attrArr];
      this.loading = false;
    },

    async busca_pessoa_empreend_evento_privilegios(cod_pessoa) {
      const params = {params: {cod_pessoa : cod_pessoa}}
      const response = await API.get("/configuracao/pessoa_empreend_evento_privilegios", params);
      if (Array.isArray(response.data.result))
        if (response.data.result.length > 0) {
        const apps = response.data.result.map(value => {
            return value.cod_empreend_evento;
          })
        return apps;
        }
    },

    // Por empreendimento -------------------------------------------------------------
    adicionarTodosEmpreendimento(p_codEmpreendimento) {
      const eventos = this.empreend_evento.filter((o) => o.cod_empreendimento ==  p_codEmpreendimento)
      if (eventos && eventos[0].items) {
        eventos[0].items.map((o) => { 
          if (!this.attrArr.find((attr) => attr == o.cod_empreend_evento ))
            this.attrArr.push(o.cod_empreend_evento);
        })
      }
    },
    removerTodosEmpreendimento(p_codEmpreendimento) {
      const eventos = this.empreend_evento.filter((o) => o.cod_empreendimento ==  p_codEmpreendimento)
      if (eventos && eventos[0].items) {
        eventos[0].items.map((o) => { 
          let idx_evento = this.attrArr.findIndex((attr) => attr == o.cod_empreend_evento )
          if (idx_evento >= 0) {
            this.attrArr.splice(idx_evento, 1)
            this.attrArr = [...this.attrArr]
          }
        })
      }
    },

    // Todos ---------------------------------------------------------------------
    adicionarTodos() {
      this.empreend_evento.map((app) => {
        if (app && app.items && app.items.length > 0) {
          app.items.map((o) => { 
            if (!this.attrArr.find((attr) => attr == o.cod_empreend_evento ))
              this.attrArr.push(o.cod_empreend_evento);
          })
        }
      })
    },
    removerTodos() {
      this.attrArr = []
    },

    // Adicionar do Usuario -----------------------------------------------------
    async copiarDoUsuario(p_PessoaSelecionado) {
      const attrArrAux = await this.busca_pessoa_empreend_evento_privilegios( p_PessoaSelecionado.cod_pessoa );
      this.attrArr = [];
      this.attrArr.push(...attrArrAux);
    },

    remove (id) {
      let idx = this.attrArr.indexOf(id)
      this.attrArr.splice(idx, 1)
      this.attrArr = [...this.attrArr]
    },

    async changed(){
      //console.log(this.attrArr)
      sortBy(this.attrArr, { prop: "cod_empreend_evento" });
      this.$emit('changed', this.attrArr)
    },

    cancelar() {
      this.attrArr = [];
      this.attrArr.push(...this.attrArrAux);
    },

    async validate() {
      var ls_Res = null;
      const lo_Obj =  {
                        cod_pessoa : this._cod_pessoa
                      }
      //const apps = this.dados_eventos.find((o) => { o.cod_aplicacao_evento ==  })
      //const arr = this.dados_eventos.filter(obj => obj.items.filter((o) => this.attrArr.includes(cod_aplicacao_evento)));
      //let arr = this.dados_eventos.every(o => o.items.every(elem => { console.log('elem::', elem); this.attrArr.includes(elem.cod_aplicacao_evento) } ));
      let arr = [];
      this.empreend_evento.map(value => {
        value.items.map(value2 => {
          console.log(value.descricao);
          if (this.attrArr.includes(value2.cod_empreend_evento))
            arr.push({descricao: value.descricao, ...value2});
        })
      })

      ls_Res = await API.post(`/configuracao/pessoa_empreend_evento_privilegios/${this._cod_pessoa}`, JSON.stringify(arr));
      if (ls_Res.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }
    }

  },

};
</script>

<style scoped>

#Privilegios {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #Privilegios {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Privilegios::-webkit-scrollbar {
  width: 5px;
}

#Privilegios::-webkit-scrollbar-button {
  padding: 1px;
}

#Privilegios::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#Privilegios::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #Privilegios::-webkit-scrollbar {
    width: initial;
  }

  #Privilegios::-webkit-scrollbar-button {
    padding: initial;
  }

  #Privilegios::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Privilegios::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
  /* height: calc(100% - 125px); */
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.chips-font {
  font-weight: 300;
  height: 20px!important;
}

.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  left: 10px;
  transform: translateY(-100%);
  width: 10px; 
  height: 13px; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #F7F7F7;
}

</style>
