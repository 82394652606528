import { render, staticRenderFns } from "./PrivilegioUsuarioAplicacao.vue?vue&type=template&id=3b3ad012&scoped=true"
import script from "./PrivilegioUsuarioAplicacao.vue?vue&type=script&lang=js"
export * from "./PrivilegioUsuarioAplicacao.vue?vue&type=script&lang=js"
import style0 from "./PrivilegioUsuarioAplicacao.vue?vue&type=style&index=0&id=3b3ad012&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3ad012",
  null
  
)

export default component.exports